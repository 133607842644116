import React, { } from 'react';

const AppVersion = () => {
    const version = '1.26.0';

    return (
        <div>
            <p>App Version: {version}</p>
        </div>
    );
};

export default AppVersion;